import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import threehandsImage from './assets/threehands.png';
import UHC from './assets/UHC.png';
import Cigna from './assets/Cigna.jpg';
import Wellcare from './assets/Wellcare.png';
import Devoted from './assets/Devoted.jpg';
import './App.css';

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [acaModalOpen, setAcaModalOpen] = useState(false); // New state for ACA Marketplace modal

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleAcaModal = () => {
    setAcaModalOpen(!acaModalOpen); // Toggle ACA Marketplace modal
  };

  return (
    <div className="App">
      {/* Helmet to update the head tags */}
      <Helmet>
        <title>Affordable Health Insurance & Medicare Coverage | Policy Masterr</title>
        <meta name="description" content="Policy Masterr helps you find affordable health insurance and Medicare coverage that fits your needs. Compare ACA Marketplace plans, Medicare, and Final Expense options to make informed decisions today." />
      </Helmet>
      <header className="App-header">
        <div className="header-content">
          <h2 className="logo">
          <a href="/" className="logo-link">
            <span className="policy">Policy</span><span className="masterr">Masterr</span>
            </a>
          </h2>
          <p className="top-bar-content">
            (346) 253-2556 | Mon - Fri, 8 AM - 8 PM ET
          </p>
        </div>
      </header>

      <main className="App-main">
        <div className="main-content">
          <h1>Find Affordable Health Coverage. Feel Better.</h1>
          <p>
            At Policy Masterr, we’re dedicated to helping you find affordable,
            personalized coverage that fits your life. By comparing quotes from
            multiple insurance providers, we ensure you make informed decisions
            on your health {/*and life */}insurance needs. Click to compare ACA Marketplace or Medicare Plans today?
          </p>

          {/* Informational message */}
          <div className="info-message">
            <p>
              Clicking these buttons will redirect you to our partner websites to compare available health plans.
            </p>
          </div>
          
          <div className="button-group">
          <button onClick={() => window.open("https://www.healthsherpa.com/?_agent_id=emmanuel-agbakpe", "_blank")}>
  ACA Marketplace
</button>
<button onClick={() => window.open("https://planenroll.com/?purl=Emmanuel-Agbakpe", "_blank")} title="Click to compare Medicare plans">
  Medicare
</button>
          </div>
        </div>
        <div className="image-container">
          <img src={threehandsImage} alt="Family" />
        </div>
      </main>

      {/* Insurance company logos section */}
      <section className="insurance-logos">
        <h2>Trusted Insurance Providers</h2>
        <div className="logos">
          <img src={UHC} alt="United Healthcare" className="logo" />
          <img src={Cigna} alt="Cigna" className="logo" />
          <img src={Wellcare} alt="Wellcare" className="logo" />
          <img src={Devoted} alt="Devoted" className="logo" />
        </div>
      </section>

      <footer className="app-footer"></footer>

      {/* Modal for ACA Marketplace */}
      {acaModalOpen && (
  <div className="modal-overlay" onClick={toggleAcaModal}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <h2>Compare ACA Marketplace Plans on Health Sherpa</h2> {/* Updated header */}
      <p>
        You will be redirected to Health Sherpa to compare ACA Marketplace plans.
      </p>
      <iframe
        src="https://www.healthsherpa.com/?_agent_id=emmanuel-agbakpe"
        width="100%"
        height="500px"
        title="Health Sherpa ACA Marketplace Comparison"
      ></iframe>
      <button className="close-modal" onClick={toggleAcaModal}>Close</button>
    </div>
  </div>
)}

      {/* Modal for PlanEnroll iframe */}
      {modalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Compare Medicare and Final Expense Plans</h2>
            <p>
              You will be viewing our partner platform, PlanEnroll, to compare
              options for Medicare and Final Expense plans. 
            </p>
            <iframe
              src="https://planenroll.com/?purl=Emmanuel-Agbakpe"
              width="100%"
              height="500px"
              title="PlanEnroll Comparison"
            ></iframe>
            <button className="close-modal" onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
